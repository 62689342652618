// Libraries
import {Environment, GraphQL, Platform} from '@supermove/sdk';

// App
import packageJson from '../package';

const {version, build} = packageJson;
const AppInfo = {
  getBuildNumber: () => build,
  getVersion: () => version,
  name: Environment.getAppName(),
  platform: Platform.OS,
};

const cache = GraphQL.createCache();
const client = GraphQL.createClient({cache, appInfo: AppInfo});
const appSyncClient = GraphQL.createAppSyncClient({cache});

export {AppInfo, client, appSyncClient};
