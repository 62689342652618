// Libraries
import React from 'react';

// Supermove
import {Browser} from '@supermove/app';

// App
import {client} from 'config';

export const wrapRootElement = ({element}) => <Browser client={client}>{element}</Browser>;
